<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-card>
            <div slot="header">
              <strong>Update Configuration</strong>
            </div>
            <b-row>
              <b-col sm="12">
                <form-group :validator="$v.form.key"
                            label="Key"
                            :use-horizontal="false">
                  <b-form-input type="text"
                                placeholder="Enter your key"
                                autocomplete="off"
                                v-model="form.key"
                                disabled></b-form-input>
                </form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <form-group :validator="$v.form.value"
                            label="Value"
                            :use-horizontal="false">
                  <b-form-select v-if="form.inputType == 5"
                                 v-model="form.value"
                                 :options="options.enabledDisabled"></b-form-select>
                  <vue-numeric-input v-else-if="form.inputType == 3"
                                     v-model="form.value"
                                     :controls="false"
                                     className="form-control"
                                     width="100%"></vue-numeric-input>
                  <b-form-input v-else-if="form.inputType != 1"
                                :type="inputTypes[form.inputType]"
                                placeholder="Enter your value"
                                autocomplete="off"
                                v-model="form.value"></b-form-input>
                  <b-textarea v-else
                              placeholder="Enter your value"
                              autocomplete="off"
                              v-model="form.value"></b-textarea>
                </form-group>
              </b-col>
            </b-row>
            <div slot="footer">
              <b-button @click="$router.go(-1)" size="sm"> Back </b-button>
              <b-button type="submit"
                        size="sm"
                        variant="success"
                        class="float-right">
                Save Changes
              </b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { required } from "vuelidate/lib/validators";
  import { mapGetters } from "vuex";
  import { enabledDisabled } from "@/shared/options";

  export default {
    data: () => ({
      route: {
        form: "ConfigurationCreate",
        table: "Configuration",
      },
      form: {
        key: null,
        inputType: null,
        value: null,
      },
      inputTypes: ["text", "textarea", "number", "decimal", "date", "boolean", "email"],
      options: {
        enabledDisabled,
      },
    }),
    validations: {
      form: {
        key: { required },
        value: { required },
      },
    },
    created() {
      const self = this;
      self.get();
    },
    computed: {
      ...mapGetters("auth", ["user", "token"]),
    },
    methods: {
      get() {
        const self = this;

        let loader = self.$loading.show();
        self.$store
          .dispatch("apis/get", {
            url: `/common/configuration/${self.$route.params.id}`,
            params: {
              buildingId: self.$route.params.buildingId,
            }
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });

              self.$router.push({ name: self.route.table });
            } else {
              self.form = {
                key: response.data.key,
                inputType: response.data.type,
                value: response.data.value,
              };
            }
            loader.hide();
          });
      },
      handleSubmit() {
        const self = this;

        self.$v.form.$touch();
        if (self.$v.form.$pending || self.$v.form.$error) return;

        let _confirmText = "You are about to update this user. Are you sure ?",
          _okText = "Yes, Update",
          _action = "apis/put",
          _url = `/common/configuration/${self.$route.params.id}?buildingId=${self.$route.params.buildingId}`;

        self.$dialog
          .confirm(_confirmText, {
            okText: _okText,
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch(_action, {
                url: _url,
                params: {
                  ...self.form,
                  buildingId: self.$route.params.buildingId,
                },
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });
                  if (response.data === "mcst.defaultbuilding") {
                    self.handleLogout();
                    return;
                  }
                  self.$router.go(-1);
                }
              });
          });
      },
      handleLogout() {
        const self = this;

        self.$store
          .dispatch("apis/get", {
            url: "auth/logout",
            params: {
              refreshToken: self.token.refreshToken,
            },
          })
          .then((response) => {
            localStorage.removeItem("vuex");

            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.$message.success({
                zIndex: 10000,
                message: response.message,
              });
            }
            window.location.reload();
          });
      },
    },
  };
</script>
