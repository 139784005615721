export const paymentMethod = [
  { label: "Cash", id: 0 },
  { label: "Cheque", id: 1 },
  { label: "Online", id: 2 },
  { label: "Others", id: 3 },
];

export const status = [
  { label: "Active", id: true },
  { label: "Inactive", id: false },
];

export const role = [
  {
    id: 0,
    label: "Admin",
  },
  {
    id: 1,
    label: "Tenant",
  },
  {
    id: 2,
    label: "Building User",
  },
];

export const yesNo = [
  { label: "Yes", id: true },
  { label: "No", id: false },
];

export const enabledDisabled = [
  { text: "Enabled", value: "1" },
  { text: "Disabled", value: "0" },
];

export const available = [
  { label: "Available", id: true },
  { label: "Unavailable", id: false },
];

export const openClose = [
  { label: "Closed", id: true },
  { label: "Opened", id: false },
];

export const autoSend = [
  { label: "Auto Send", id: true },
  { label: 'Manual click "Send"', id: false },
];

export const inputType = [
  { label: "Text", id: 0 },
  { label: "Text Area", id: 1 },
  { label: "Number", id: 2 },
  { label: "Date", id: 3 },
];

export const paymentStatus = [
  { id: 0, label: "Unpaid" },
  { id: 1, label: "Partial Payment" },
  { id: 2, label: "Full Payment" },
];
